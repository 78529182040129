import type { Currency } from "@product/rosetta-sdk";
import { Campaign } from "@product/rosetta-sdk";
import { useAtomValue } from "jotai";
import { useCallback } from "react";

import { rosettaAtom } from "scmp-app/lib/rosetta";

export const useRosettaCampaign = () => {
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const getActiveCampaignSettings = useCallback(() => {
    if (typeof window === "undefined" || !asyncRosettaState?.result?.instance) {
      return null;
    }

    const appConfig = asyncRosettaState?.result.instance?.getRosettaAppConfig();
    const userType = asyncRosettaState?.result?.v2?.user?.scmpUserType;
    const userCurrency = ((asyncRosettaState?.result?.hasPianoExperienceExecuted &&
      window.tp?.customVariables?.currency) ??
      asyncRosettaState?.result?.instance?.rulesEngine.getOutcome("guest.currency")) as Currency;

    if (!appConfig?.campaigns || !userType || !userCurrency) {
      return null;
    }

    return Campaign.getActiveCampaignSettings({
      campaigns: appConfig.campaigns,
      user: {
        currency: userCurrency,
        type: userType,
      },
    });
  }, [
    asyncRosettaState?.result?.hasPianoExperienceExecuted,
    asyncRosettaState?.result?.instance,
    asyncRosettaState?.result?.v2?.user?.scmpUserType,
  ]);

  return {
    getActiveCampaignSettings,
  };
};
