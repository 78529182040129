/**
 * @generated SignedSource<<73a830fcf909777c0cfdabc4f049d1d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type atomsCurrentTakeoverAdSectionsAtomBase$data = {
  readonly __typename: "Article";
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "atomsCurrentTakeoverAdSectionsAtomBase";
} | {
  readonly __typename: "Section";
  readonly fullSectionPath: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "atomsCurrentTakeoverAdSectionsAtomBase";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "atomsCurrentTakeoverAdSectionsAtomBase";
};
export type atomsCurrentTakeoverAdSectionsAtomBase$key = {
  readonly " $data"?: atomsCurrentTakeoverAdSectionsAtomBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"atomsCurrentTakeoverAdSectionsAtomBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "atomsCurrentTakeoverAdSectionsAtomBase"
};

(node as any).hash = "3b59f03b1ffc59ac7ac58957e801dbee";

export default node;
