import rosettaSdk, { Campaign } from "@product/rosetta-sdk";
import { PageType } from "@product/scmp-sdk";
import { useAtomValue } from "jotai/index";
import { useCallback, useMemo } from "react";

import { useCurrentPageType } from "shared/hooks";

import { useTakeoverBottomAdSlot } from "scmp-app/components/advertisement/takeover-ad-slot/hooks";
import { gdprAtom } from "scmp-app/components/tracking/gdpr";
import { useRosettaCampaign } from "scmp-app/lib/rosetta/hooks/rosetta-campaign";

export const useRosettaCampaignPopup = () => {
  const { isAccepted: isAcceptedGDPR } = useAtomValue(gdprAtom);
  const currentPageType = useCurrentPageType();
  const { isEnableTakeoverBottomAdSlot } = useTakeoverBottomAdSlot({
    variant: "home",
  });

  const registerRosettaCampaignPopupService = useCallback(() => {
    if (currentPageType !== PageType.Homepage) return;

    rosettaSdk.registerService("campaignPopUp");
    rosettaSdk.getService("campaignPopUp")?.addCampaignPopUpScript();
  }, [currentPageType]);

  const { getActiveCampaignSettings } = useRosettaCampaign();

  const needToShow = useMemo(() => {
    const activeCampaignSettings = getActiveCampaignSettings();
    if (!activeCampaignSettings) return false;

    const campaignPopupSettings = Campaign.getCampaignPopupSettings(activeCampaignSettings);
    if (!campaignPopupSettings) return false;

    return !!(
      campaignPopupSettings.checkShouldShowCampaignPopupByDisplayInterval() &&
      isAcceptedGDPR &&
      PageType.Homepage === currentPageType &&
      !isEnableTakeoverBottomAdSlot
    );
  }, [currentPageType, isAcceptedGDPR, isEnableTakeoverBottomAdSlot, getActiveCampaignSettings]);

  return {
    registerRosettaCampaignPopupService,
    shouldShowRosettaCampaignPopup: needToShow,
  };
};
